/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { DicomMetadataStore, MODULE_TYPES } from '@ohif/core';

import Dropzone from 'react-dropzone';
import filesToStudies from '../Local/filesToStudies';

import { extensionManager } from '../../App';

import { Icon, Button, LoadingIndicatorProgress } from '@ohif/ui';
import JSZip from 'jszip';
import { createExtractorFromData } from 'node-unrar-js';

type LocalProps = {
  modePath: string;
};

// This functions reads the rar file
async function readRarFile(file: File) {
  return new Promise<ArrayBuffer>(r => {
    const reader = new FileReader();
    reader.addEventListener('load', event => {
      r(event.target?.result as ArrayBuffer);
    });
    reader.readAsArrayBuffer(file);
  });
}
function s3({ modePath }: LocalProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const dropzoneRef = useRef();
  const [dropInitiated, setDropInitiated] = React.useState(false);

  // Initializing the dicom local dataSource
  const dataSourceModules = extensionManager.modules[MODULE_TYPES.DATA_SOURCE];
  const localDataSources = dataSourceModules.reduce((acc, curr) => {
    const mods = [];
    curr.module.forEach(mod => {
      if (mod.type === 'localApi') {
        mods.push(mod);
      }
    });
    return acc.concat(mods);
  }, []);

  const firstLocalDataSource = localDataSources[0];
  const dataSource = firstLocalDataSource.createDataSource({});

  const microscopyExtensionLoaded = extensionManager.registeredExtensionIds.includes(
    '@ohif/extension-dicom-microscopy'
  );

  const onDropUsingS3Link = async acceptedFile => {
    const dcmFIleResponse = await fetch(acceptedFile);
    const dcmFIleResponseBlob = await dcmFIleResponse.blob();
    const file = new File([dcmFIleResponseBlob], 'name');
    onDrop([file]);
  };

  const getExtractFiles = (extractedFiles, isRar = false) => {
    let arrayOfFiles = [];
    if (!isRar) {
      extractedFiles.forEach((relativePath, file) => {
        arrayOfFiles.push(file);
      });
    } else {
      arrayOfFiles = extractedFiles;
    }

    arrayOfFiles = arrayOfFiles.filter(file => {
      const fileName = file.name.split('/')[1];
      return fileName?.length;
    });
    const finalArrayOfFiles = arrayOfFiles.map(async (file, index) => {
      let content;
      // change unit8Array to buffer to create content blob
      try {
        content = await file.async('blob');
      } catch (e) {
        content = new Blob([file.extraction.buffer]);
      }

      const fileName = file.name.split('/')[1];
      let extractedFile = null;
      if (fileName === 'DICOMDIR') {
        extractedFile = new File([content], fileName, { type: '' });
      } else if (fileName.length) {
        extractedFile = new File([content], fileName, {
          type: 'application/dicom',
        });
      }

      if (extractedFile) {
        return extractedFile;
      }
    });

    //Filter unwanted and untracked files
    return finalArrayOfFiles.filter(file => file);
  };

  const onDropFolderUsingS3Link = async acceptedFile => {
    const dcmFIleResponse = await fetch(acceptedFile);

    const dcmFIleResponseBlob = await dcmFIleResponse.blob();

    let fileName = 'file.zip';
    let isRar = false;
    //check for rar file
    if (
      dcmFIleResponseBlob.type === 'application/vnd.rar' ||
      dcmFIleResponseBlob.type === 'application/rar'
    ) {
      fileName = 'file.rar';
      isRar = true;
    }
    const file = new File([dcmFIleResponseBlob], fileName, {
      type: dcmFIleResponseBlob.type,
    });

    const zip = new JSZip();

    let extractedFiles;
    let extractorData: any;

    if (isRar) {
      //Read rar file
      extractorData = await readRarFile(file);

      //Fetch unrar web assembly to unrar the rar file data
      //Todo: upload on graphite aws
      const wasmBinary = await (
        await fetch(
          'https://graphite-public-prod.s3.ap-south-1.amazonaws.com/unrar.wasm',
          { credentials: 'same-origin' }
        )
      ).arrayBuffer();

      //extractor instance
      const extractor = await createExtractorFromData({
        wasmBinary,
        data: extractorData,
      });

      extractedFiles = [];

      //Extract and get all files extraction
      const { files } = extractor.extract({
        files: fileHeader => !fileHeader.flags.encrypted,
      });
      for (const file of files) {
        extractedFiles.push({
          ...file,
          fileName: file.fileHeader.name,
          name: file.fileHeader.name,
          directory: file.fileHeader.flags.directory ? 'Yes' : 'No',
          content: file.extraction,
        });
      }
    } else {
      extractedFiles = await zip.loadAsync(file);
    }

    const arrayOfFiles = Promise.all(getExtractFiles(extractedFiles, isRar));

    arrayOfFiles.then(data => {
      onDrop(data);
    });
  };

  const onDrop = async acceptedFiles => {
    const studies = await filesToStudies(acceptedFiles, dataSource);

    const query = new URLSearchParams();

    if (microscopyExtensionLoaded) {
      // TODO: for microscopy, we are forcing microscopy mode, which is not ideal.
      //     we should make the local drag and drop navigate to the worklist and
      //     there user can select microscopy mode
      const smStudies = studies.filter(id => {
        const study = DicomMetadataStore.getStudy(id);
        return (
          study.series.findIndex(
            s => s.Modality === 'SM' || s.instances[0].Modality === 'SM'
          ) >= 0
        );
      });

      if (smStudies.length > 0) {
        smStudies.forEach(id => query.append('StudyInstanceUIDs', id));

        modePath = 'microscopy';
      }
    }

    // Todo: navigate to work list and let user select a mode
    studies.forEach(id => query.append('StudyInstanceUIDs', id));
    query.append('datasources', 'dicomlocal');
    setDropInitiated(false);
    navigate(`/${modePath}?${decodeURIComponent(query.toString())}`);
  };

  // Set body style
  useEffect(() => {
    setDropInitiated(true);
    const locationSearch = location.search;
    const s3Link = locationSearch.replace('?link=', '');
    if (s3Link.length) {
      const s3LinkSplit = s3Link.split('.');
      const s3LinkExtention = s3LinkSplit[s3LinkSplit.length - 1];
      if (s3LinkExtention === 'zip') {
        onDropFolderUsingS3Link(s3Link);
      }
      if (s3LinkExtention === 'dcm') {
        onDropUsingS3Link(s3Link);
      }
      onDropFolderUsingS3Link(s3Link);
    }
  }, []);

  return (
    <Dropzone
      ref={dropzoneRef}
      onDrop={acceptedFiles => {
        setDropInitiated(true);
        onDrop(acceptedFiles);
      }}
      noClick
    >
      {({ getRootProps }) => (
        <div {...getRootProps()} style={{ width: '100%', height: '100%' }}>
          <div className="h-screen w-screen flex justify-center items-center ">
            <div className="py-8 px-8 mx-auto bg-secondary-dark drop-shadow-md space-y-2 rounded-lg">
              <div className="text-center space-y-2 pt-4">
                {dropInitiated ? (
                  <div className="flex flex-col items-center justify-center pt-48">
                    <LoadingIndicatorProgress
                      className={'w-full h-full bg-black'}
                    />
                  </div>
                ) : (
                  <div className="space-y-2">
                    <p className="text-blue-300 text-base">
                      Wait while we load your files from s3
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  );
}

export default s3;
